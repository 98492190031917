import Picture from "../picture";
import ThumbnailPicture from "../thumbnailpicture";

export default function Connection({ images, contacts }) {

    if (!images) {
        return null;
    }

    const imagesToShow = images.slice(0, 3);
    const myTelegram = contacts.telegram_connection;

    return (
        <div className="container-fluid">
            <div className="connection">
                <div className="row">
                    <div className="col-12 col-sm-6 connection__first-panel">
                        <Picture
                            baseUrl="/img/thumb-up/thumb-up"
                            alt=""
                            className="connection__thumb-up"
                            width={85}
                            height={85}
                        />
                        <div className="connection__description">
                            <h3 className="d-mobile-only">Мы на связи</h3>
                            <h3 className="d-desktop-only">Мы поддерживаем связь</h3>
                            <div className="connection__offsite-events">на наших выездных мероприятиях</div>
                            <div className="text-muted">
                                Окружите себя целеустремлёнными и заряженными на успех людьми!
                            </div>
                        </div>
                        <div className="d-desktop-only connection__chevron-right">
                            <img src="/img/icons/icon-chevron-gallery-right.svg" alt="" />
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 connection__gallery">
                        {imagesToShow.map((image, idx) => (
                            <div className="connection__photo" key={idx}>
                                <a href={image.url} target="_blank" rel="noreferrer" title={image.title}>
                                    <ThumbnailPicture
                                        thumbnail={image.thumbnail}
                                        width={124}
                                        height={124}
                                        alt={image.title}
                                        lazy
                                    />
                                </a>
                            </div>
                        ))}

                        <div className="connection__telegram">
                            <a href={myTelegram} title="Наш телеграм">
                                <img src="/img/telegram-plane.svg" alt="Telegram" /> <span>Наш Telegram</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
